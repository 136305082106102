import "./Snackbar.css";

export const Snackbar = () => {
	return (
		<>
			<div className="snackbar">
				<span className="text">
					{/* 10% Discount on the Booking Amount till 14 Apr 2024, 11:59 PM. Discount applied while choosing payment method. */}
					Discount available on select packages for limited time.
				</span>
			</div>
		</>
	);
};
