import { Box, Button, Step, StepButton, Stepper } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import * as React from "react";
import { ImageGallary } from "../ImageGallary";
import "./ItinararyDesktop.css";
// import route1 from "../../../media/dusty/routes/leh2leh.png"

export const ItinararyDesktop = ({ data }) => {
	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});

	const dayData = data[activeStep];

	const totalSteps = () => {
		return data.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  data.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	return (
		<div className="trip-itinarary-desktop">
			<h1>Trip Itinerary</h1>
			<div className="stepper">
				<Stepper nonLinear activeStep={activeStep}>
					{data.map((item, index) => (
						<Step key={index} completed={completed[index]}>
							<StepButton
								style={{ width: "80px" }}
								color="inherit"
								onClick={handleStep(index)}
							>
								{item.day}
							</StepButton>
						</Step>
					))}
				</Stepper>
			</div>
			<div className="day-details">
				<div className="itinarary-content">
					<p className="place-heading">
						{dayData.day}: <span>{dayData.title}</span>
					</p>
					<div className="place-details">
						<div className="tags">
							{dayData.tags.map((tag, index) => (
								<p className="tag" key={tag.key}>
									{tag.key}: <span>{tag.value}</span>
								</p>
							))}
						</div>
						<p className="place-description">
							{dayData.description}
						</p>
					</div>
				</div>
				{/* <div className="itinarary-gallary">
					<ImageGallary />
				</div> */}
				<img src={dayData.imageURl} height={500} width={700}/>
			</div>

			<div>
				<React.Fragment>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							pt: 2,
						}}
					>
						<Button
							color="inherit"
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							Back
						</Button>
						<Box sx={{ flex: "1 1 auto" }} />
						<Button onClick={handleNext} sx={{ mr: 1 }}>
							Next
						</Button>
					</Box>
				</React.Fragment>
			</div>
		</div>
	);
};