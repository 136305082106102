import { DustyCard } from "../../components/dustyPackage/DustyCard";
import { PackageCard } from "../../components/PackageCard/PackageCard";
import { packagesCardInfoList } from "../../models/packages.model";
import "./Packages.css";
import user1 from "../../media/user/user1.jpg";

export const Packages = () => {
	// const tripInfo = {
	//   title: 'Srinagar to Manali (Via Leh)',
	//   rating: '4.9',
	//   duration: '11 Days',
	//   places: '9 Places',
	//   distance: '~ 1700 kms',
	//   description: 'The one that takes you from the scenic landscapes and stunning beauty of Kashmir to the grey mountains of Ladakh before finally ending at the evergreen hill station of Manali. Best for explorers!',
	//   originalPrice: '₹36,900',
	//   discountedPrice: '₹31,900',
	//   imageUrl: user1, // Replace with the actual image path
	// };

	return (
		<>
			<section className="packages">
				<div className="package-container">
					<h2>Get Dusty on our Trips</h2>

					<div className="p-row">
						{/* <DustyCard trip={tripInfo} />
            <DustyCard trip={tripInfo} />
            <DustyCard trip={tripInfo} />
            <DustyCard trip={tripInfo} /> */}

						{packagesCardInfoList.map((packageInfo) => {
							return (
								// <PackageCard
								//   data={packageInfo}
								// />
								<DustyCard trip={packageInfo} />
							);
						})}
					</div>
				</div>
			</section>
		</>
	);
};
