import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import route1 from "../../../media/dusty/routes/leh2leh.png";
import { ImageGallary } from "../ImageGallary";
import "./ItinararyMobile.css";

export const ItinararyMobile = ({ data }) => {
	return (
		<div className="trip-itinarary-mobile">
			<h2>Trip Itinerary</h2>
			<div className="collapse">
			{/* <img src={route1} height={200} width={400}/> */}
			<img src={data[0].imageURl}/>
				{data.map((dayData, index) => (
								
					<Accordion key={index}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`panel${index}a-content`}
							id={`panel${index}a-header`}
							style={{ paddingInlineStart: 0, margin: 0 }}
							className="collapse_summary"
						>
							<div className="collapse_header">
								<div className="collapse_day">
									<span>{dayData.day}</span>
								</div>
								<div className="collapse_heading">
									<p className="collapse_heading_text">
										{dayData.title}
									</p>
								</div>
							</div>
						</AccordionSummary>
						<AccordionDetails className="collapse_details">
							<div className="day-details">
								{/* <div className="itinarary-gallary">
									<ImageGallary />
								</div> */}
								<div className="itinarary-content">
									<div className="place-details">
										<div className="tags">
											{dayData.tags.map((tag, index) => (
												<p
													className="tag"
													key={tag.key}
												>
													{tag.key}:{" "}
													<span>{tag.value}</span>
												</p>
											))}
										</div>
										<p className="place-description">
											{dayData.description}
										</p>
									</div>
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		</div>
	);
};