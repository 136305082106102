import goa from "../media/goa.jpg";
import leh2leh from "../media/dusty/HomePage/leh2leh.jpg";
import manali2sri from "../media/dusty/HomePage/manali2sri.jpg";
import sri2manali from "../media/dusty/HomePage/sri2manali.jpg";
import spiti from "../media/dusty/HomePage/spiti.jpg";
import sriumingmanali from "../media/dusty/HomePage/landing8.jpg";
import lehuming from "../media/dusty/HomePage/landing3.jpg";
import lehumingmanali from "../media/dusty/HomePage/landing2.jpg";

export const packageList = [
  {
    path: "leh2leh",
    packageName: "Leh to Leh"
  },
  {
    path: "manali2srinagar",
    packageName: "Manali to Srinagar"
  },
  {
    path: "srinagar2manali",
    packageName: "Srinagar to Manali"
  },
  {
    path: "spiti",
    packageName: "Spiti Valley"
  },
  {
    path: "sriumingmanali",
    packageName: "Srinagar To Manali (Leh - Umingla)"
  },
  {
    path: "lehuming",
    packageName: "Explore Leh (Umingla)"
  },
  {
    path: "lehumingmanali",
    packageName: "Leh to Manali (Umingla)"
  },
]

export const packagesCardInfoList = [
  {
    packageName: "Leh to Leh",
    description: "Embark on a breathtaking Leh to Leh biking odyssey, traversing Himalayan peaks, serene valleys, and ancient monasteries—a soul-stirring adventure awaits!",
    img: leh2leh,
    days: "7 Days",
    places: "5 Places",
    distance: "~ 850 kms",
    rating: 4.9,
    price1: "₹ 28,900",
    price2: "₹ 24,900",
    path: "leh2leh",
  },
  {
    packageName: "Srinagar to Manali (Via Leh)",
    description: "The one that takes you to the from scenic landscapes and stunning beauty of Kashmir to the grey mountains of Ladakh before finally ending at the evergreen hill station of Manali. Best for explorers!",
    img: sri2manali,
    days: "11 Days",
    places: "9 Places",
    distance: "~ 1700 kms",
    rating: 4.8,
    price1: "₹ 35,900",
    price2: "₹ 30,900",
    path: "srinagar2manali",
  },
  {
    packageName: "Manali to Srinagar (Via Leh)",
    description: "The one that takes you to the from evergreen hill station of Manali to the grey mountains of Ladakh before finally ending at the scenic landscapes and stunning beauty of Kashmir. Best for explorers!",
    img: manali2sri,
    days: "11 Days",
    places: "9 Places",
    distance: "~ 1700 kms",
    rating: 4.8,
    price1: "₹ 35,900",
    price2: "₹ 30,900",
    path: "manali2srinagar",
  },
  {
    packageName: "Spiti Valley (Manali to Manali)",
    description: "Explore Spiti Valley's rugged beauty and ancient monasteries on our curated adventure. Unforgettable landscapes await for the rider in you!",
    img: spiti,
    days: "9 Days",
    places: "6 Places",
    distance: "~ 800 kms",
    rating: 4.7,
    price1: "₹ 29,900",
    price2: "₹ 25,900",
    path: "spiti",
  },
  {
    packageName: "Srinagar To Manali (Leh - Umingla)",
    description: "The one that takes you to the from scenic landscapes and stunning beauty of Kashmir to the hill station of Manali while showing you the mighty passes of Ladakh including the Tso Moriri Lake.",
    img: sriumingmanali,
    days: "11 Days",
    places: "11 Places",
    distance: "~ 1600 kms",
    rating: 4.8,
    price1: "₹ 39,900",
    price2: "₹ 34,900",
    path: "sriumingmanali",
  },
  {
    packageName: "Explore Leh (Umingla)",
    description: "Explore Leh's Umingla region, boasting breathtaking landscapes, cold desert in Nubra Valley, serene monasteries, and the enchanting Moriri and Pangong Lakes. A journey of awe and wonder awaits.",
    img: lehuming,
    days: "8 Days",
    places: "7 Places",
    distance: "~ 950 kms",
    rating: 4.7,
    price1: "₹ 34,900",
    price2: "₹ 30,900",
    path: "lehuming",
  },
  {
    packageName: "Leh to Manali (Umingla)",
    description: "Go on an exquisite from Leh to the hill station of Manali while exporing the mighty mountains, scenic monastries, Umingla, and the enchanting Moriri and Pangong Lakes. Adventure awaits for the rider in you!",
    img: lehumingmanali,
    days: "9 Days",
    places: "8 Places",
    distance: "~ 1150 kms",
    rating: 4.7,
    price1: "₹ 37,900",
    price2: "₹ 32,900",
    path: "lehumingmanali",
  },
];
