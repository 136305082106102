// BlogsContainer.jsx
import React from 'react';
import { BlogCard } from './BlogCard';
import './BlogContainer.css'; // Make sure to create a corresponding CSS file
import testImg from "../../media/chennai/nature1.jpg"
import blog1Img from "../../media/dusty/Blog/blog1.jpg"
import blog2Img from "../../media/dusty/Blog/blog2.jpg"
import blog3Img from "../../media/dusty/Blog/blog3.jpg"
// import blog4Img from "../../media/dusty/Blog/blog4.jpg
import { Link } from "react-router-dom";
import { WhatsAppButton } from '../WhatsAppButton/WhatsAppButton';

const blogsData = [
  {
    id: 1,
    image: blog1Img,
    category: 'July 25, 2023',
    title: 'Riding the Thrill: Embracing the Adventure Biking Brotherhood',
    author: 'Rohan Sharma',
    readTime: '5 min read',
    blogUrl: 'blog1'
  },
  {
    id: 2,
    image: blog2Img,
    category: 'December 15, 2023',
    title: "Navigating Ladakh's Majesty: Choosing the Ideal Season for a Bike Expedition",
    author: 'Sumit Shah',
    readTime: '4 min read',
    blogUrl: 'blog2'
  },
  {
    id: 3,
    image: blog3Img,
    category: 'Februrary 9, 2024',
    title: ' Navigating Perils: Understanding the Dangers of a Ladakh Bike Expedition',
    author: 'Kunal Garg',
    readTime: '6 min read',
    blogUrl: 'blog3'
  },
  // {
  //   id: 4,
  //   image: blog4Img,
  //   category: 'Typography',
  //   title: 'What Fonts Are Trending Now and Font Trends for 2023',
  //   author: 'Grace Fussell',
  //   readTime: '11 min read'
  // },
  // ... more blog entries
];

export const BlogContainer = () => {
  return (
    <>
    <h2 className='section-title-blog'> Experiences</h2>
    <div className="blogs-container">
      {blogsData.map((blog) => (
        <Link to={`/blogs/${blog.blogUrl}`} style={{ textDecoration: 'none' }}>
          <BlogCard key={blog.id} blog={blog} />
        </Link>
      ))}
    </div>
    <WhatsAppButton/>
    </>

  );
};
