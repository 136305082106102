import React, { useEffect } from 'react';

const SimpleModal = ({ isOpen, onClose, onSave }) => {
  useEffect(() => {
    if (isOpen) {
      // Freeze the background by disabling scroll on the body
      document.body.style.overflow = 'hidden';
    }

    return () => {
      // Re-enable scrolling when the modal is not open
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleCountrySelect = (country) => {
    onSave(country);
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start', // Align modal at the top
      paddingTop: '10vh', // Add padding at the top for aesthetics
      zIndex: 1000, // Ensure the modal is above all other content
    }}>
      <div style={{
        background: '#fff',
        borderRadius: '8px',
        padding: '20px',
        maxWidth: '400px',
        width: '100%',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        {/* <h2 style={{ color: '#333', margin: '0 0 20px' }}>Select Your Country</h2> */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <button
            onClick={() => handleCountrySelect('India')}
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', cursor: 'pointer', background: '#d5b15b', color: '#000' }}>
            I am an Indian
          </button>
          <button
            onClick={() => handleCountrySelect('Other')}
            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc', cursor: 'pointer', background: '#d5b15b', color: '#000' }}>
            I am a Foreigner
          </button>
        </div>
        {/* <button onClick={onClose} style={{ marginTop: '20px', padding: '10px 15px', background: '#f44336', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', display: 'block', marginLeft: 'auto' }}>
          Close
        </button> */}
      </div>
    </div>
  );
};

export default SimpleModal;
