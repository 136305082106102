import React, { useState } from "react";
import { Link } from "react-router-dom";
import { packageList } from "../../models/packages.model";
import bookingimg from "../../media/booking.png";
import "./NavbarDesktop.css";

export const NavbarDesktop = () => {
	const [isChecked, setIsChecked] = useState(false);

	const handleButtonClick = () => {
		setIsChecked(!isChecked);
	};

	return (
		<div className="navbar_desktop_container">
			<ul className="list">
				<li id="active" onClick={handleButtonClick}>
					<Link to="/">Home</Link>
				</li>
				<li onClick={handleButtonClick}>
					<Link to="/about" id="d">
						About Us
					</Link>
				</li>
				<li onClick={handleButtonClick}>
					<Link to={{}} id="d">
						Packages
					</Link>
					<ul>
						{packageList.map((element, index) => {
							return (
								<li
									key={index}
									className="package-link"
									onClick={handleButtonClick}
								>
									<Link
										to={`/packages/${element.path}`}
										id="d"
									>
										{element.packageName}
									</Link>
								</li>
							);
						})}
					</ul>
				</li>
				<li onClick={handleButtonClick}>
					<Link to="/blogs" id="d">
						Blogs
					</Link>
				</li>
				<li onClick={handleButtonClick}>
					<Link to="/contact" id="d">
						Contact Us
					</Link>
				</li>
			</ul>
		</div>
	);
};
