import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
// import { BlogsComponent } from "./components/Blog/Blog";
import { BlogPage } from "./components/Blog/BlogPage";
import { Footer } from "./components/Footer";
import Navbar from "./components/Navbar";
import { Snackbar } from "./components/Snackbar";
import { allPackageDetails } from "./models/package.model";
import { packageList } from "./models/packages.model";
import { About } from "./pages/About/About";
import { Contact } from "./pages/Contact";
import { Error } from "./pages/Error";
import { Home } from "./pages/Home";
import { Package } from "./pages/packages/Package";
import { Packages } from "./pages/packages/Packages";
import { BlogContainer } from "./components/Blog/BlogContainer";
import { blogList } from "./models/blog.model";
import { BlogPage2 } from "./components/Blog/BlogPage2";
import { BlogPage3 } from "./components/Blog/BlogPage3";
import { Terms } from "./components/Blog/Terms";
import { Privacy } from "./components/Blog/Privacy";
import { Refund } from "./components/Blog/Refund";
import React, {useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-L7BD0DH0ET"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	
	return (
		<div className="App">
			<Router>
				<Snackbar />
				<Navbar />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/packages" element={<Packages />} />
					{packageList.map((element, index) => (
						<Route
							key={index}
							path={`/packages/${element.path}`}
							element={
								<Package
									data={allPackageDetails[element.path]}
								/>
							}
						/>
					))}
					<Route path="/blogs" element={<BlogContainer />} />
					<Route path="/blogs/blog1" element={<BlogPage/>} />
					<Route path="/blogs/blog2" element={<BlogPage2 />} />
					<Route path="/blogs/blog3" element={<BlogPage3 />} />
					
					<Route path="/contact" element={<Contact />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/refund" element={<Refund />} />
					<Route path="*" element={<Error />} />
				</Routes>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
