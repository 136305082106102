import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import "./FixCard.css";

// Updated Styled card container with media query for responsiveness
const CardContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px -2px 10px rgba(0,0,0,0.1);
  padding: 16px; // Optimized for mobile
  box-sizing: border-box;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 100;

  // Media query for larger screens
  @media (min-width: 768px) {
    padding: 20px; // Increased padding for larger screens
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display:none;
  }
`;

// Updated Card Content with responsiveness in mind
const CardContent = styled.div`
  text-align: center;

  // Example of adjusting content for larger screens
  @media (min-width: 768px) {
    font-size: 18px; // Larger text on wider screens
  }
`;

// Card Component remains the same
export const BottomFixedCard = ({packageName, paymentId}) => {
    useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form2");
    
    if (!rzpPaymentForm.hasChildNodes()) {

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = paymentId;
      rzpPaymentForm.appendChild(script);

    }

  });

  return(
        <>
        <CardContainer>
    <CardContent>
      {/* <div class='parent'>
  <div class='child'><p>Hello world</p></div>
  <div class='child'><form id="rzp_payment_form2"></form></div>
    </div> */}
    <div class="container-why">
  <div class="box-why box1-why">
    <span className='box1-package'>{packageName}</span>
    <div>Book&#160;@&#160;₹7500</div>
    {/* <div>Book&#160;@&#160;<span class="original-price-trip">₹7500</span>&#160;₹6750</div> */}
  </div>
  <div class="box-why box2-why"><form id="rzp_payment_form2"></form></div>
</div>

    </CardContent>
  </CardContainer>
        </>
    );
 };

