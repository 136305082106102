import { ContactUs } from "../components/ContactUs/ContactUs";
import Form from "../components/Form";
import contactbg from "../media/contactus.png";
import "./Contact.css";

export const Contact = () => {
  return (
    <>
      {/* <section className="contact" id="contact"> */}
        <ContactUs/>
        {/* <h2>
          Contact us
        </h2>
        <div className="row">
          <div className="img">
            <img src={contactbg} id="contactimg" alt={""} />
          </div>
          <Form />
        </div>
      </section>
      <div className="callbox">
        <i className="fas fa-phone"></i> +91-1112223333
      </div>
      <div className="callbox">
        <i className="fas fa-envelope"></i> info.dustyterrain@gmail.com
      </div> */}
    </> 
  );
};
