import React from 'react';
import './BlogPage.css'; // Assuming you have an accompanying CSS file
import blog1Img from "../../media/dusty/Blog/blog1.jpg"
import { WhatsAppButton } from '../WhatsAppButton/WhatsAppButton';

export const BlogPage = () => {
  return (
    <>
    <div className="blog-page">
      <div>
        <img src={blog1Img}/>
      </div>
      <h1>Riding the Thrill: Embracing the Adventure Biking Brotherhood</h1>
      <h2>Introduction</h2>
      <p>
      Adventure biking isn't just a ride; it's a lifestyle, and at its heart is a community that transforms the journey. Let's explore why we adore the adventure biking community – a tribe that elevates the experience beyond the open road.  
      </p>
      <h2>1. Ever-Ready Comrades:</h2>
      <p>What sets this community apart is its relentless support. Always ready to help, whether fixing a flat tire or sharing a word of advice, the camaraderie runs deep.</p>
      <h2>2. Knowledge and Parts Exchange:</h2>
      <p>
      Preparedness is key, and adventure bikers willingly share their wealth of knowledge and spare parts, fostering unity through shared experiences and expertise.  
      </p>
      <h2>3. Chance Encounters, Lifelong Bonds:</h2>
      <p>
      The open road often leads to chance meetings that evolve into enduring friendships. Shared adventures and overcoming challenges together create lasting connections.  
      </p>
      <h2>4. Conquering Adversity Together:</h2>
      <p>
      From navigating black ice to conquering water crossings, experienced riders guide and assist, strengthening community bonds through shared triumphs over adversity.  
      </p>
      <h2>5. Community: The Backbone of Biking:</h2>
      <p>
      Adventure biking is not just an individual pursuit; it's a collective experience. The community, organizing group rides and sharing tips, is what makes the adventure sustainable and enriching.  
      </p>
      <h2>Conclusion:</h2>
      <p>
      As we navigate unknown terrains, let's celebrate the adventure biking community – a brotherhood that makes the journey not just exhilarating, but a shared odyssey of the heart.  
      </p>
      <div className='author-name'>
        <h3>Rohan Sharma</h3><h3>July 25, 2023</h3>
      </div>
    </div>
    <WhatsAppButton/>
    </>
  );
};
