import React from 'react';
import './BlogPage.css'; // Assuming you have an accompanying CSS file

export const Terms = () => {
  return (
    <div className="blog-page">
      <h1>Terms & Conditions</h1>
      <ul>
        <li> Booking amount is mandatory to confirm your booking. (The amount may vary time to time) Balance payment to be made 30 days prior to departure.</li>
        <li> If full and final amount of the package is not received by 30 days prior to the departure date, the booking stands cancelled without any prior intimation and there will be no refund.</li>
        <li>Participants are responsible of their belongings.</li>
        <li>The safety and security measures have to be followed to ensure safe journey</li>
        <li>We will send you things to carry list and how to pack your bag for the trip to ensure you bring everything.</li>
        <li>Driving and riding safely on road is entirely your responsibility Dusty Terrain will not be responsible for the same.</li>
        <li>In the event of an accident, illness and/or injury our team will help you to get help but the amount for same will be borne by participants.</li>
        <li> In high altitude terrain you need to follow the guidelines which is meant to keep you healthy</li>
        <li>By registering to the trip you confirm that You are physically and mentally fit for the particular trip.</li>
        <li>You will have to pay for any damage to vehicle or property or other element.</li>
        <li><h2>IMPORTANT INFORMATION</h2></li>
        <ul>
                <li>People who choose with Bike+fuel package have to pay security deposit of Rs.5000/- Which is refundable you once handover the bike at the end of the trip. Bikes will be provided on day one in evening after proper checkup and all paperwork. Without security no bikes will be provided.</li>
                <li>Trip schedule is subjected to change as per road, weather and other unavoidable conditions so take a day or two in buffer. This is not an ordinary place its "Leh Ladakh". Only Team members have the right to take decision depending on above. If the participants leave the bikes in the middle of the trip they have to bear the transportation cost</li>
                <li>Any extra cost (Transportation/ Accommodation) arising due to natural calamities like, landslides, road blockage, political disturbances or change in law will be extra. We will follow the guidelines of all the countries/ territories/Unions which we cross during the trip.</li>
                <li>Any Accommodation / stay apart from fixed schedule (as per the itinerary) will be charged extra.</li>
                <li>Strictly No suitcase/Trolley bags are allowed only 1 backpack bag is allowed in Backup vehicle/ Luggage Vehicle. We provide luggage vehicle however customer will be responsible for their own luggage. Extra bag pack will be charged extra.</li>
                <li>Participants will be responsible for their personal belongings on the trip; Dusty Terrain will not be responsible for the same</li>
                <li>Kindly note this is expedition is not an ordinary trip so mentally prepare yourself for the same, take it as a challenge.</li>
        </ul>
      </ul>
    </div>
  );
};
