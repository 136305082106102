import React, { useState } from "react";
import { Link } from "react-router-dom";
import { packageList } from "../../models/packages.model";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styles from "./NavbarMobile.module.css";

export const NavbarMobile = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [packageOpen, setPackageOpen] = useState(false);

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	const handleClickPackage = () => {
		setPackageOpen(!packageOpen);
	};

	const drawerList = () => (
		<List className={styles.list}>
			<ListItem
				button
				component={Link}
				to="/"
				onClick={toggleDrawer}
				className={styles.list_item}
			>
				<ListItemText primary="Home" />
			</ListItem>
			<ListItem
				button
				component={Link}
				to="/about"
				onClick={toggleDrawer}
				className={styles.list_item}
			>
				<ListItemText primary="About Us" />
			</ListItem>
			<ListItem
				button
				onClick={handleClickPackage}
				className={styles.list_item}
			>
				<ListItemText primary="Packages" />
				{packageOpen ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={packageOpen} timeout="auto" unmountOnExit>
				<List
					component="div"
					disablePadding
					className={styles.package_list}
				>
					{packageList.map((element, index) => (
						<ListItem
							button
							key={index}
							component={Link}
							to={`/packages/${element.path}`}
							onClick={toggleDrawer}
							classes={{}}
							className={styles.list_item}
						>
							<ListItemText
								inset
								primary={element.packageName}
								className={styles.package_list_item}
							/>
						</ListItem>
					))}
				</List>
			</Collapse>
			<ListItem
				button
				component={Link}
				to="/blogs"
				onClick={toggleDrawer}
				className={styles.list_item}
			>
				<ListItemText primary="Blogs" />
			</ListItem>
			<ListItem
				button
				component={Link}
				to="/contact"
				onClick={toggleDrawer}
				className={styles.list_item}
			>
				<ListItemText primary="Contact Us" />
			</ListItem>
		</List>
	);

	return (
		<div className={styles.navbar_mobile_container}>
			<IconButton
				edge="start"
				color="white"
				aria-label="menu"
				onClick={toggleDrawer}
				className={styles.icon_button}
			>
				<MenuIcon className={styles.menu_icon} />
			</IconButton>
			<Drawer
				anchor="left"
				open={isDrawerOpen}
				onClose={toggleDrawer}
				classes={{ paper: styles.drawer }}
			>
				{drawerList()}
			</Drawer>
		</div>
	);
};
