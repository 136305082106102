// TestimonialsContainer.jsx
import React, { useState, useRef, useEffect} from 'react';
import { TestimonialCard } from './TestimonialCard';
import './TestimonialContainer.css'; // Make sure to create a corresponding CSS file
import user1 from "../../media/user/user1.jpg";


const testimonialsData = [
  {   id:1,
      image: require("../../media/dusty/HomePage/pujatandon.jpg"),
      text: "I was always skeptical about travel companies because most of them don’t deliver what they promise. However, Dusty Terrain provided very good accommodation and facilities and everything was managed perfectly. I went on the biking trip in Leh with my husband and both of us really enjoyed our experience.",
      name: "Puja Tandon",
      position: "Gurugram",
  },
  {   id:2,
      image: require("../../media/dusty/HomePage/ashokvada.jpg"),
      text: "I am really glad i chose Dusty Terrain for my Ladakh Trip from 19 to 27 June. They were very professional and hospitable. Parvinder Ji, was an extraordinary road captain with a lot of knowledge about biking and the places we visited. Will return back next year for another trip.",
      name: "Ashok vada",
      position: "Hyderabad",
  },
  {   id:3,
      image: require("../../media/dusty/HomePage/shellythukral.jpg"),
      text: "Kudos to Dusty Terrain company for their Travel Credits policy! Had to cancel a trip, and their 50% cash refund + 50% travel credits made the cancellation stress free. I was offered another trip which suited my schedule which worked out well. Arrangements during the trip were also great",
      name: "Shelly Thukral",
      position: "Delhi",
  },
  {   id:4,
      image: require("../../media/dusty/HomePage/deepak.jpg"),
      text: "I went on their Manali to Srinagar trip and I must say that I was am really happy that I chose them. Being seasoned rider, I always want the bikes to be top notch and the bike which they provided was in great condition. They also provided a photographer who captured all our memories. Everyone should go with them at least once.",
      name: "Deepak Singh",
      position: "Pune",
  },
  {   id:5,
      image: require("../../media/dusty/HomePage/robin.jpg"),
      text: "Went to Spiti Valley. Parvinder Ji, went out of his way to help us during the trip. He is really experienced and assisted me even though I did not have a lot of mountain riding experience. They also do a bike riding workshop before the trip which is a must do",
      name: "Robin Chakco",
      position: "Kerala",
  },
  {   id:6,
      image: require("../../media/dusty/HomePage/aditibisht.jpg"),
      text: "I went to Ladakh with them during 2023 season. As a solo female traveler, their meticulous planning, well-maintained bikes, and supportive guides made the journey both thrilling and secure.",
      name: "Aditi Bisht",
      position: "Delhi",
  }
];

export const TestimonialContainer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const ref = useRef(null);
  const [containerWidth, setWidth] = useState(100 + "%");
  const [animationState, setPlay] = useState("paused");
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.scrollWidth + "px");
      setPlay("running");
    }
  }, []);
  console.log(containerWidth);

  return (
    <>
    <h2 className='section-title-test'>What our clients say?</h2>
    <div className="testimonials-container">
      <div className="testimonials-scroll-container">
      {/* <div
        className="d-flex"
        ref={ref}
        style={{
          width: `${containerWidth}`,
          animationPlayState: animationState
        }}
      > */}
        {testimonialsData.map((testimonial) => (
          <TestimonialCard key={testimonial.id} testimonial={testimonial} />
        ))}
        {/* </div> */}
      </div>
    </div>
    </>
  );
};

