import { WhatsAppButton } from '../../components/WhatsAppButton/WhatsAppButton';
import aboutImage from '../../media/about.jpg'; // Replace with the path to your video thumbnail
import './About.css'; // Make sure to link the CSS file

export const About = () => {
  // You would add functionality to handle the video modal popup
  return (
    <>
    <div className="about-us-container">
      <div className='about-us-text-section'>
        <div className="about-us-content">
          <h1 className="about-us-title">The Journey</h1>
          <div className="about-us-text">
            <p>
            Welcome to Dusty Terrain, a manifestation of the shared passion for mountain exploration that unites three individuals. Our journey began unexpectedly in the dynamic ambiance of a calisthenics gym, where the clanging of weights and palpable energy set the stage for what would become an extraordinary venture.
            </p>
            <br/>
            <p>
            In 2010, our founder, Parvinder Ji, kindled the flame of adventure through a casual conversation over drinks. This sparked an impromptu Leh bike trip on a 150cc Pulsar, undertaken without riding gear. From the rain-soaked roads of Gurgaon to the challenging terrains of Baralacha Pass, our expedition unfolded amidst unforeseen challenges and the breathtaking beauty of the mountains.
            </p>
            <br/>
            <p>Navigating knee-high water crossings and icy descents, our journey faced a setback in Pang due to sudden snowfall, leaving the bike non-functional. Timely assistance from fellow bikers proved invaluable, guiding us back on track. Our exploration extended to Leh, Pangong, and Khardungla Pass, with the missed opportunity to visit Nubra due to time constraints and heavy snow.</p>
            <br/>
            <p>
            Venturing through the scenic landscapes of Kashmir, our odyssey included a poignant stop in Kargil and a night in a Shikara in Srinagar. The return to Gurgaon became an epic non-stop ride, surpassing 24 hours. Parvinder Ji's adventure epitomizes the Dusty Terrain spirit – defined by spontaneity, resilience, and an unwavering love for the thrill of exploration. Join us as we share stories, tips, and a passion for adventure that fuels our journey into the heart of the mountains.
            </p>
            <br/>
            <p>
            Welcome to Dusty Terrain, where every trail is a tale waiting to be told.
            </p>
          </div>
        </div>
        <div className="about-us-content">
          <h1 className="about-us-title">Our Vision</h1>
          <div className="about-us-text">
            <p>At Dusty Terrain, our vision is to craft indelible memories for people of all ages and genders during their mountain expeditions. We aspire to deliver top-notch services that transcend conventional boundaries, introducing the thrill of Mountain Biking in India to the world. We believe in unlocking the full potential of mountain adventures, making each trip a unique and unforgettable experience.</p>
          </div>
        </div>
      </div>

      <div className="about-us-image">
        <img src={aboutImage} alt="Why Everyone Must Trek" />
      </div>
    </div>
    <WhatsAppButton/>
    </>
  );
};

