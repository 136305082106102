import CheckIcon from "@mui/icons-material/Check";
import "./ItemChecklist.css";

export const ItemChecklist = ({ data }) => {
  return (
    <div className="item-list">
      {data.map((item, index) => (
        <div className="packing-item" key={index}>
          <CheckIcon />
          <p>{item}</p>
        </div>
      ))}
    </div>
  );
};
