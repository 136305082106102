import React from 'react';
import './WhatsAppButton.css'; // Import CSS for styling

export const WhatsAppButton = () => {
  return (
    <div className="whatsapp-button">
      <a
        href="https://wa.me/918800509901" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-link"
      >
        {/* <img
          src="whatsapp-icon.png" // Replace with your WhatsApp icon image
          alt="WhatsApp Icon"
          className="whatsapp-icon"
        /> */}
        <div className='whatsappicon'>
        <i className ="fab fa-whatsapp" aria-hidden="true"></i>
        </div>
      </a>
    </div>
  );
};

