import "./DatesTable.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { PricingTable } from "./PricingTable";
import React, { useState, useEffect } from 'react';

export const DatesTable = ({data}) => {
  // console.log(data);
  // console.log(itemPricingData);
  // useEffect(() => {
  //   const rzpPaymentForm = document.getElementById("rzp_payment_form2");
    
  //   if (!rzpPaymentForm.hasChildNodes()) {

  //     const script = document.createElement("script");
  //     script.src = "https://checkout.razorpay.com/v1/payment-button.js";
  //     script.async = true;
  //     script.dataset.payment_button_id = "pl_NcMgCR3QUzwGQz";
  //     rzpPaymentForm.appendChild(script);

  //   }

  // });

  const accordionSummeryStyle = {
    fontSize: "5rem",
  };

  const itemPricing = [
    {
      package: "Single Rider",
      transport: "Self Bike/Car",
      price: "₹ 31,900/- Per Person",
    },
    {
      package: "One Bike,One Rider",
      transport: "RE 350/500 cc + Fuel",
      price: "₹ 51,900/- Per Person",
    },
    {
      package: "One Bike,Two Riders",
      transport: "RE 350/500 cc + Fuel",
      price: "₹ 41,900/- Per Person",
    },
    {
      package: "Seat in SUV or Pickup",
      transport: "One Exclusive Seat",
      price: "₹ 51,900/- Per Person",
    },
  ];

  return (
    <div className="trip-date">
      {data.map((item, index) => (
  <Accordion key={index}>
    <AccordionSummary
      style={accordionSummeryStyle}
      expandIcon={<ArrowDownwardIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
  >
      {/* <span className="question">{item.fromDate} - </span><span className="question"> {item.toDate}</span> */}
      <div className="collapse_header">
      <div className="collapse_heading">
                <p className="collapse_heading_text">{item.fromDateDay}</p>
									<p className="collapse_heading_text">
                  {item.fromDate}
									</p>
								</div>
								<div className="collapse_heading">
                <p className="collapse_heading_text">{item.toDateDay}</p>
									<p className="collapse_heading_text">
                  {item.toDate}
									</p>
								</div>
                <div className="collapse_heading_right right-div">
                  <p className="collapse_heading_text red">{item.saleWord}
                    </p>
                    <p className="collapse_heading_text back-yello">{item.offPercent}
                    </p>
								</div>
                <div className="collapse_heading_right">
									<p className="collapse_heading_text">Book&#160;@
									</p>
                 <p className="collapse_heading_text">₹ 7500</p>
                  {/* <p className="collapse_heading_text"><span class="original-price-trip">₹7500</span>&#160;₹6750</p> */}
              
								</div>
							</div>
    </AccordionSummary>
    <div className="answer">
      {/* {console.log(itemPricingData)} */}
      <PricingTable data={item.pricing} fromDate={item.fromDate}/>
      {/* <p>{item.toDate}</p> */}
    </div>
  </Accordion>
))}
    </div>
  );
};

