import React, { useState } from 'react';
import Cookies from 'js-cookie';
import SimpleModal from './SimpleModal'; // Import the SimpleModal component

class CountryBasedRedirect extends React.Component {
  state = { showModal: false };

  componentDidMount() {
    this.checkUserCountryAndRedirect();
  }

  checkUserCountryAndRedirect = () => {
    const userCountry = Cookies.get('userCountry'); // Get the userCountry cookie
    if (userCountry) {
      this.redirectBasedOnCountry(userCountry);
    } else {
      this.setState({ showModal: true });
    }
  };

  redirectBasedOnCountry = (country) => {
    const indiaURL = 'https://dustyterrain.com/';
    const internationalURL = 'https://global.dustyterrain.com/';

    if (country.toLowerCase() === 'india') {
    } else {
      window.location.href = internationalURL;
    }
  };

  saveCountryAndRedirect = (country) => {
    Cookies.set('userCountry', country, { expires: 7 }); // Set cookie for 7 days
    this.setState({ showModal: false });
    this.redirectBasedOnCountry(country);
  };

  render() {
    return (
      <div>
        {/* Redirecting you to the appropriate version of our website... */}
        <SimpleModal
          isOpen={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          onSave={this.saveCountryAndRedirect}
        />
      </div>
    );
  }
}

export default CountryBasedRedirect;
