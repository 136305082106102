// Modal.js
import React from 'react';
import { Contact } from '../pages/Contact';
import './Modal.css'; // Import the CSS for styling

export const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <Contact/>
        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};
