import React, { useState, useEffect }  from 'react';
import { Modal } from '../Modal';
import './MailButton.css'; // Import CSS for styling

export const MailButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
  return (
    <div className="mail-button">
        <button type='button' onClick={toggleModal}>
            <div className='mailicon'>
            <i className ="fa fa-envelope" aria-hidden="true"></i>
            </div>
        </button>
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

