import React from 'react';
import './whyTrip.css'; // Your CSS file for styling

// Icon components
// import AdventureIcon from './icons/AdventureIcon';
// import PeopleIcon from './icons/PeopleIcon';
// import DistanceIcon from './icons/DistanceIcon';

const statsData = [
  {
    id: 1,
    number: '15+',
    label: 'Years of Adventure Biking Experience',
  },
  {
    id: 2,
    number: '1000+',
    label: 'Satisfied Travellers',
  },
  {
    id: 3,
    number: '2Mn+',
    label: 'KMs and Counting',
  },
];
const services = [
	{
		id: 1,
		title: "In-House Photographer",
		description:
			"Professional photographer to capture your priceless moments. Be sure to get those stunning DPs and candids.",
		icon: "fas fa-camera",
	},
	// {
	// 	id: 2,
	// 	title: "Best Flight Deals",
	// 	description:
	// 		"We partner with major airlines to get you preferred (unpublished)  prices for your trips. Book with us and save more.",
	// 	icon: "fas fa-plane-departure",
	// },
	{
		id: 2,
		title: "Bike Riding Workshop",
		description:
			"Riding for the first time? No Problem. You’ll get access to free bike riding workshops from our trip captains before your trip.",
		icon: "fas fa-motorcycle",
	},
	// {
	// 	id: 4,
	// 	title: "100% Refund",
	// 	description:
	// 		"Book with peace of mind. Get 100% refund of booking amount within 7 days.",
	// 	icon: "fas fa-rupee-sign",
	// },
	{
		id: 3,
		title: "No Cost EMI",
		description:
			"Book your trips stress-free with our no-cost EMI payment option on major credit cards. Book now and pay flexibly.",
		icon: "fas fa-credit-card",
	},
	// {
	// 	id: 6,
	// 	title: " Premium Service",
	// 	description:
	// 		"Meticulously maintained bikes guarantee exhilarating rides through rugged terrains, while carefully chosen accommodations provide post-adventure comfort.",
	// 	icon: "fas fa-medal",
	// },
];

const StatCard = ({ number,icon }) => {
	return (
		<div className="service-card-why">
			<div className="icon">
				<i className={icon}></i>
			</div>
			<h3 className="title-choose">{number}</h3>
			{/* <p className="description">{description}</p> */}
		</div>
	);
};

export const WhyTrip = () => {
  return ( 
	<>
		{/* <h2 className="section-title-choose">Why Choose Us?</h2> */}
    	<div className="services-container">
      {services.map(stat => (
        <StatCard key={stat.id} number={stat.title} icon={stat.icon} />
      ))}
    </div>
	</>
  );
};
