import './PackingList.css';

export const PackingList = ({data}) => {
    return (
        <div className="packingListContainer">
            {/* <h2>Packing List</h2> */}
            {data.map((packItem, index) => (
                <div className="packingListItem" key={index}>
                    <h3>{packItem.item}</h3>
                    <ul>
                        {packItem.details.map((detail, detailIndex) => (
                            <li key={detailIndex}>{detail}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};
