import React from "react";
import "./TravelCard.css"; // Import the CSS file for styles
import Rating from "@mui/material/Rating/Rating";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TourIcon from "@mui/icons-material/Tour";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import { Link, useNavigate } from "react-router-dom";

// Component for the travel card
export const DustyCard = ({ trip }) => {
	const navigate = useNavigate();
	const navigateToPackages = (tripPath) => {
		// 👇️ navigate to /contacts
		console.log(tripPath);
		navigate(`/packages/${tripPath}`);
	  };
	
	return (
		<div className="card">
			<img className="card-image" src={trip.img} alt="Scenic view" />
			<div className="card-content">
				<div className="card-title">
					<div>
					<i className="fas fa-map-marker-alt"></i> {trip.packageName}
					</div>					
					<span className="card-rating">{trip.rating}</span>
				</div>
				<div className="card-info">
					<DateRangeIcon />
					<span>{trip.days}</span>
					<TourIcon />
					<span>{trip.places} </span>
					<AddRoadIcon />
					<span>{trip.distance}</span>
				</div>
				<p className="card-description">{trip.description}</p>
			</div>
			<div className="button_container">
			<div className="card-pricing">
					<span>From </span><span className="original-price">{trip.price1}</span>
					<span className="discounted-price">{trip.price2}</span>
				</div>
				<Link to={`https://dustyterrain.com/packages/${trip.path}`}>
					<button className="card-button">View Details</button>
				</Link>
			</div>
		</div>
	);
};
