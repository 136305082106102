// ContactForm.js
import React from 'react';
import styles from './ContactUs.module.css'; // Using CSS modules for styling
import { useForm, ValidationError } from '@formspree/react';
import { WhatsAppButton } from '../WhatsAppButton/WhatsAppButton';

export const ContactUs = () => {
  const [state, handleSubmit] = useForm("mzbnrlwq");
  if (state.succeeded) {
      return <p>We have received your query. We will get back to you shortly!</p>;
  }

  return (
    <>
    <div className={styles.formContainer}>
      <h2 className={styles.title}>Message us</h2>
      <p className={styles.instructions}>
        Fill in the information required in the form and we will get back to you within
        24 hrs.
      </p>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label htmlFor="name" className={styles.label}>Name*</label>
        <input type="text" id="name" name="name" required className={styles.input} placeholder="Your Name" />

        <label htmlFor="email" className={styles.label}>Email*</label>
        <input type="email" id="email" name="email" required className={styles.input} placeholder="Your Email" />

        <label htmlFor="phone" className={styles.label}>Phone number*</label>
        <input type="tel" id="phone" name="phone" required className={styles.input} placeholder="+91" />

        <label htmlFor="subject" className={styles.label}>Subject</label>
        <input type="text" id="subject" name="subject" className={styles.input} placeholder="Subject" />

        <label htmlFor="message" className={styles.label}>Message</label>
        <textarea id="message" name="message" className={styles.textarea} placeholder="Your Message"></textarea>
        <button type="submit" className={styles.button}>Send</button>
      </form>
    </div>
    <WhatsAppButton/>
    </>
  );
};
