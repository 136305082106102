import { WhyChooseUs } from "../components/ChooseUs/ChooseUs";
import { LandingComponent } from "../components/LandingCompo/LandingCompo";
// import { LandingPage } from "../components/LandingImage/LandingPage";
import { StatsSection } from "../components/stats/StatsSection";
import { NewTest, newTest } from "../components/testimonials/NewTest";
import { Testimonials } from "../components/testimonials/Testimonials";
import TestimonialsScroll from "../components/TestimonialScroll/TestimonialScroll";
import "./Home.css";
import user1 from "../media/user/user1.jpg";
import { TestimonialContainer } from "../components/dustyTest/TestimonialContainer";
import { Packages } from "./packages/Packages";
import bgvideo from "../media/dustyback.mp4";
import { TravelCard } from "../components/locations/TravelCard";
import { DustyCard } from "../components/dustyPackage/DustyCard";
import { WhatsAppButton } from "../components/WhatsAppButton/WhatsAppButton";
import CountryBasedRedirect from "../CountryBasedRedirect";
import { MailButton } from "../components/MailButton/MailButton";

export const Home = () => {
	const testimonials = [
		{
			text: "This product has significantly improved my daily workflow!",
			author: "Jane Doe",
			photoUrl: require("../media/user/user1.jpg"),
		},
		{
			text: "Absolutely wonderful service and team!",
			author: "John Smith",
			photoUrl: user1,
		},
		{
			text: "Absolutely wonderful service and team!",
			author: "John Smith",
			photoUrl: user1,
		},
		{
			text: "Absolutely wonderful service and team!",
			author: "John Smith",
			photoUrl: user1,
		},
		{
			text: "Absolutely wonderful service and team!",
			author: "John Smith",
			photoUrl: user1,
		},
		{
			text: "Absolutely wonderful service and team!",
			author: "John Smith",
			photoUrl: user1,
		},
		{
			text: "Absolutely wonderful service and team!",
			author: "John Smith",
			photoUrl: user1,
		},
		// Add more testimonials as needed
	];

	const departureData = [
		{
			startDay: "Tuesday",
			startDate: "Feb 27, 2024",
			endDay: "Saturday",
			endDate: "Mar 9, 2024",
			originalPrice: "$2,475",
			discountPercentage: "5% off",
			finalPrice: "$2,351",
		},
		{
			startDay: "Tuesday",
			startDate: "Mar 12, 2024",
			endDay: "Saturday",
			endDate: "Mar 23, 2024",
			originalPrice: "", // No original price means no sale
			discountPercentage: "", // No discount info
			finalPrice: "$2,599",
		},
		{
			startDay: "Thursday",
			startDate: "Apr 11, 2024",
			endDay: "Monday",
			endDate: "Apr 22, 2024",
			originalPrice: "$3,775",
			discountPercentage: "25% off",
			finalPrice: "$2,831",
		},
	];

	const infoArray = [
		{
			iconClassName: "fas fa-envelope",
			infoText: "throttle@dustyterrain.com",
			anchorHref: "mailto:throttle@dustyterrain.com",
		},
		{
			iconClassName: "fab fa-instagram",
			infoText: "@dustyterrain",
			anchorHref: "https://instagram.com/dustyterrain",
		},
		// {
		// 	iconClassName: "fas fa-phone-alt",
		// 	infoText: "+91-8708939225",
		// 	anchorHref: "tel:8708939225",
		// },
	];

	return (
		<>
			{/* <LandingComponent/> */}
			<div className="container">
				<section className="home" id="home">
					<div className="content">
						<h3>
							Welcome to <span id="diff">D</span>usty{" "}
							<span id="diff">T</span>errain
						</h3>
						<p>Where Mountains Meet Memories</p>
						{/* <Link className="btn" to="/packages">
              <button className="exp-btn">Explore</button>
            </Link> */}
					</div>

					<div className="video-container">
						<video
							src={bgvideo}
							id="video-slider"
							loop
							autoPlay
							muted
						></video>
					</div>

					<div className="info_container">
						{infoArray.map((item) => (
							<div>
								<a
									href={item.anchorHref}
									className="info_element"
								>
									<i
										className={`${item.iconClassName} info_icon`}
									/>
									<span className="info_text">
										{item.infoText}
									</span>
								</a>
							</div>
						))}
					</div>
				</section>
			</div>
			<StatsSection />
			{/* <PackageSection /> */}
			<Packages />
			<WhyChooseUs />
			{/* <TravelCard trip={tripInfo} />
      <DustyCard trip={tripInfo} /> */}
			<TestimonialContainer />
			<WhatsAppButton/>
			<MailButton/>
			<CountryBasedRedirect/>
		</>
	);
};
