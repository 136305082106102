import React from "react";
import { Link } from "react-router-dom";
import dustyLogo from "../../media/DustyTerrainLogo.png";
import "./styles.css";
import { NavbarDesktop } from "./NavbarDesktop";
import { NavbarMobile } from "./NavbarMobile";

const Navbar = () => {
	return (
		<nav>
			<div className="logo">
				<Link to="/">
					<img src={dustyLogo} width={200} height={50}></img>
				</Link>
			</div>
			<NavbarDesktop />
			<NavbarMobile />
		</nav>
	);
};

export default Navbar;
