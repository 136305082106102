import LehImage from '../../media/about.jpg';
import './PackageIntro.css';
import {Modal} from './../Modal';
import React, { useState, useEffect } from 'react';

export const PackageIntro = ({data}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    // When the modal is open, add the 'no-scroll' class to body
    const rzpPaymentForm = document.getElementById("rzp_payment_form");
    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = data.payment_id;
      rzpPaymentForm.appendChild(script);
    }
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen]);
  return (
    <div className="tour-highlight">

      <div className="tour-image">
        <img src={data.image} alt="Tour Image" />
      </div>
      <div className="tour-content">
        <h1><span>{data.title}</span><span className='title'>{data.mid}</span><span>{data.end}</span></h1>
        <div className="tour-info">
          {data.tags.map((element, index) => {
            return (
              <div className="info-item"  key={index}>
                <div className="info-head">
                  <i className={element.icon} aria-hidden="true"></i>
                  <h3>{element.key}</h3>
                </div>
                <p>{element.value}</p>
              </div>
            )
          })}
        </div>
        <p className='content-description'>{data.description}</p>
        <div className='alignButton'>
          Book trip @ ₹7500 only!
          {/* Book trip @ <span class="original-price-trip">₹7500</span>&#160;₹6750 only! */}
        </div>
      <div className="button-container">
        {/* <button type='button'>Book Now</button> */}
        <form id="rzp_payment_form"></form>

        <button type='button' onClick={toggleModal}>Request More Info</button>
        <Modal isOpen={isModalOpen} onClose={toggleModal} />
        </div>
      </div>


    </div>
  );
};
