import React from 'react';
import './BlogPage.css'; // Assuming you have an accompanying CSS file

export const Privacy = () => {
  return (
    <div className="blog-page">
        <h1>Privacy Policy</h1>
      <p>By using our website www.dustyterrain.com we assure you that your privacy is important to us. We value your trust in us. We will work hard so that you can enthusiastically use our Services and recommend us to others as well with full confidence. To ensure full protection of your privacy, we promise that we will use your personal information only in ways that are compatible with the Privacy Policy. We take all measures to safeguard your information.</p>
      <p>Once you use our website, we may collect and store your personal information that is provided to us by you. We only collect personal information that we consider necessary for achieving this purpose Our purpose in doing so is to provide you safe, efficient, smooth, valuable and customized Services as per your needs.</p><p> Your information:</p>
      <p>To obtain our services you need to fill your details required on our registration form. Various information are required for different purpose such as name ,contact details, email id, date of birth, User ID, password, bank details etc We may also collect your information through the use of cookies or from our affiliates or from other parties or from other means.</p>
    </div>
  );
};
