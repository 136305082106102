import "./ReachUs.css";
import FlightIcon from "@mui/icons-material/Flight";
import TrainIcon from "@mui/icons-material/Train";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

const ReachUs = ({ data }) => {
	return (
		<div className="reachUs">
			<h2>How to Reach {data.city}</h2>
			{/* <p>{data.description}</p> */}
			<div className="optionsContainer">
				{data.options.map((option, index) => (
					<div className="optionCard" key={index}>
						<div>
							{option.icon}
							<h3>{option.vehicle}</h3>
						</div>
						<p>{option.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default ReachUs;
