import "./Package.css";
import Itinarary from "../../components/PackageComponents/Itinarary/Itinarary";
import { KeyItemsSection } from "../../components/PackageComponents/KeyItemsSection";
import { PackageIntro } from "../../components/PackageComponents/PackageIntro";
import { TripHighlights } from "../../components/PackageComponents/TripHighlights";
import ReachUs from "../../components/PackageComponents/ReachUs";
import { FAQs } from "../../components/PackageComponents/FAQs";
import { WhyChooseUs } from "../../components/ChooseUs/ChooseUs";
import { StatsSection } from "../../components/stats/StatsSection";
import { WhyTrip } from "../../components/whyTrip/whyTrip";
import { BottomFixedCard } from "../../components/fixedCard";
import { WhatsAppButton } from "../../components/WhatsAppButton/WhatsAppButton";

export const Package = ({ data }) => {
	// console.log(data)
	return (
		<>
		<div className="container">
			<PackageIntro data={data.intro} />
			<WhyTrip/>
			<KeyItemsSection data={data.keyInfoTabs} />
			<TripHighlights data={data.highlights} />
			<Itinarary data={data.itinarary} />
			<ReachUs data={data.keyInfoTabs.reachUs} />
			<FAQs data={data.faqs} />
			<BottomFixedCard packageName={data.intro.mid} paymentId={data.intro.payment_id}/>
		</div>
		<WhatsAppButton/>
		</>
	);
};