// TestimonialCard.jsx
import React from 'react';
import './TestimonialCard.css'; // Make sure to create a corresponding CSS file

export const TestimonialCard = ({ testimonial }) => (
  <div className="testimonial-card">
    <div className="testimonial-image-wrapper">
      <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
    </div>
    <div className="testimonial-info">
      <h3 className="testimonial-name">{testimonial.name}</h3>
      <p className="testimonial-position">{testimonial.position}</p>
      <blockquote className="testimonial-text">{testimonial.text}</blockquote>
    </div>
  </div>
);

