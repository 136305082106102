import React from "react";
import { ItinararyDesktop } from "./ItinararyDesktop";
import { ItinararyMobile } from "./ItinararyMobile";

const Itinarary = ({ data }) => {
	return (
		<>
			<ItinararyDesktop data={data} />
			<ItinararyMobile data={data} />
		</>
	);
};

export default Itinarary;