import React from 'react';
import './Testimonials.css'; // Your CSS file for styling
import user1 from "../../media/user/user1.jpg";

const testimonialsData = [
    {   id:1,
        image: user1,
        text: "I was always skeptical about travel companies because most of them don’t deliver what they promise. However, Dusty Terrain provided very good accommodation and facilities and everything was managed perfectly. I went on the biking trip in Leh with my husband and both of us really enjoyed our experience.",
        name: "Puja Tandon",
        location: "Gurugram",
    },
    {
        image: user1,
        text: "I am really glad i chose Dusty Terrain for my Ladakh Trip from 19 to 27 June. They were very professional and hospitable. Parvinder Ji, was an extraordinary road captain with a lot of knowledge about biking and the places we visited. Will return back next year for another trip.",
        name: "Ashok vada",
        location: "Hyderabad",
    },
    {
        image: user1,
        text: "Kudos to Dusty Terrain company for their Travel Credits policy! Had to cancel a trip, and their 50% cash refund + 50% travel credits made the cancellation stress free. I was offered another trip which suited my schedule which worked out well. Arrangements during the trip were also great",
        name: "Shelly Thukral",
        location: "Delhi",
    },
    {
        image: user1,
        text: "I went on their Manali to Srinagar trip and I must say that I was am really happy that I chose them. Being seasoned rider, I always want the bikes to be top notch and the bike which they provided was in great condition. They also provided a photographer who captured all our memories. Everyone should go with them at least once.",
        name: "Deepak Singh",
        location: "Pune",
    },
    {
        image: user1,
        text: "Went to Spiti Valley. Parvinder Ji, went out of his way to help us during the trip. He is really experienced and assisted me even though I did not have a lot of mountain riding experience. They also do a bike riding workshop before the trip which is a must do",
        name: "Santosh Yadav",
        location: "Delhi",
    },
    {
        image: user1,
        text: "Went to Spiti Valley. Parvinder Ji, went out of his way to help us during the trip. He is really experienced and assisted me even though I did not have a lot of mountain riding experience. They also do a bike riding workshop before the trip which is a must do",
        name: "Aditi Bisht",
        location: "Delhi",
    }
];

const TestimonialCard = ({ name, location, text, image }) => {
    return (
      <div className="testimonial-card">
        <img src={image} alt={`Profile of ${name}`} className="profile-image" />
        <blockquote className="testimonial-text">{text}</blockquote>
        <p className="testimonial-name">{name}</p>
        <p className="testimonial-location">{location}</p>
      </div>
    );
  };


export const Testimonials = () => {
      return (
        <section className="testimonials-section">
          <h1>Join the Adventure, Read Our Reviews</h1>
          <div className="testimonials-container">
            {testimonialsData.map(testimonial => (
              <TestimonialCard key={testimonial.id} {...testimonial} />
            ))}
          </div>
        </section>
      );

};
