import React from 'react';
import './StatsSection.css'; // Your CSS file for styling

// Icon components
// import AdventureIcon from './icons/AdventureIcon';
// import PeopleIcon from './icons/PeopleIcon';
// import DistanceIcon from './icons/DistanceIcon';

const statsData = [
  {
    id: 1,
    number: '15+',
    label: 'Years of Adventure Biking Experience',
  },
  {
    id: 2,
    number: '1000+',
    label: 'Satisfied Travellers',
  },
  {
    id: 3,
    number: '2Mn+',
    label: 'KMs and Counting',
  },
];

const StatCard = ({ number, label, icon }) => {
  return (
    <div className="stat-card">
      <div className="number">{number}</div>
      <div className="label">{label}</div>
    </div>
  );
};

export const StatsSection = () => {
  return (
    <div className="stats-container">
      {statsData.map(stat => (
        <StatCard key={stat.id} number={stat.number} label={stat.label} icon={stat.icon} />
      ))}
    </div>
  );
};
