import { Tab, Tabs } from "@mui/material";
import * as React from "react";
import { ItemChecklist } from "./ItemChecklist";
import { PackingList } from "./PackingList";
import { PricingTable } from "./PricingTable";
import "./KeyItemsSection.css";
import { DatesTable } from "./DatesTable";

export const KeyItemsSection = ({ data, faqs }) => {
  const [value, setValue] = React.useState("dates");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let componentToRender;

  switch (value) {
    case "packingList":
      componentToRender = <PackingList data={data.packingList} />;
      break;
    case "inclusions":
      componentToRender = <ItemChecklist data={data.inclusions} />;
      break;
    case "exclusions":
      componentToRender = <ItemChecklist data={data.exclusions} />;
      break;
    case "paymentPolicy":
      componentToRender = <ItemChecklist data={data.paymentPolicy} />;
      break;
    case "cancellationPolicy":
      componentToRender = <ItemChecklist data={data.cancellationPolicy} />;
      break;
    case "dates":
      componentToRender = <DatesTable data={data.dates}  />;
      break;
    default:
      componentToRender = <PricingTable data={data.dates} />;
      break;
  }

  return (
		<>
			<div className="key-info-section">
				<h1 className="key-info-heading">Package Details</h1>
				<div className="tabs">
					<Tabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons="auto" // Show scroll buttons automatically when needed
						allowScrollButtonsMobile
						centered={false}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#d5b15b"
              }
            }}
					>
						{/* <Tab label="pricing" value="pricing" /> */}
            <Tab label="Pricing & Dates" value="dates" />
            <Tab label="Inclusions" value="inclusions" />
						<Tab label="Exclusions" value="exclusions" />
            <Tab label="Payment Policy" value="paymentPolicy" />
						<Tab
							label="Cancellation Policy"
							value="cancellationPolicy"
						/>
						<Tab label="Packing List" value="packingList" />
					</Tabs>
          
				</div>
				<div className="list-container">{componentToRender}</div>
			</div>
		</>
	);
};
