import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import "./FAQs.css";

export const FAQs = ({ data }) => {

  const accordionSummeryStyle = {
    fontSize: "5rem",
  };

  return (
    <div className="faq-list-container">
      <h2>Frequently Asked Questions</h2>

      {data.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            style={accordionSummeryStyle}
            expandIcon={<ArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <div className="question">{item.question}</div>
          </AccordionSummary>
          <div className="answer">
            {item.answer}
          </div>
        </Accordion>
      ))}
    </div>
  );
};
