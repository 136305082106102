// BlogCard.jsx
import React from 'react';
import './BlogCard.css'; // Make sure to create a corresponding CSS file

export const BlogCard = ({ blog }) => {
  const handleClick = (myLink) => () => {
    window.location.href=myLink;
  }
  return (
    <>
    <div className="blog-card" onClick={() => handleClick('/blogs/blog1')}>
      <div className="blog-image-container">
        <img src={blog.image} alt={blog.title} className="blog-image" />
      </div>
      <div className="blog-content">
        <span className="blog-category">{blog.category}</span>
        <h3 className="blog-title">{blog.title}</h3>
        <p className="blog-author">{blog.author}</p>
        <div className="blog-read-time">
          <span>{blog.readTime}</span>
        </div>
      </div>
    </div>
    </>

  );
};

